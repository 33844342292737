import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountQuery } from '@cf/temp/services';

export const superAdminGuard: CanActivateFn = () => {
  const router = inject(Router);
  const accountQuery = inject(AccountQuery);

  if (!accountQuery.getValue()?.is_superuser) {
    return router.parseUrl('/');
  }

  return true;
};
