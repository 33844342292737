export interface ILang {
  label: string;
  value: LanguageEnum;
}

export enum LanguageEnum {
  en = 'en',
  fr = 'fr',
  es = 'es',
}

export const LANGUAGES: ILang[] = [
  {
    label: 'EN',
    value: LanguageEnum.en,
  },
  {
    label: 'FR',
    value: LanguageEnum.fr,
  },
  {
    label: 'ES',
    value: LanguageEnum.es,
  },
];
