import { Injectable } from '@angular/core';
import { loadSpace } from '@usersnap/browser';
import { InitOptions } from '@usersnap/browser/dist/types';
import { environment } from '../../../../environments/environment';

const USERSNAP_GLOBAL_API_KEY = 'c6659bf1-c115-4830-97cf-dd6bd1d0de98';

@Injectable()
export class UsersnapService {
  public usersnapApi: any | null = null;

  initialize(initParams: InitOptions = {}) {
    return new Promise<any>((resolve) => {
      loadSpace(USERSNAP_GLOBAL_API_KEY).then((api) => {
        void api.init({
          ...initParams,
          custom: { hash: environment.buildVersion },
        });
        this.usersnapApi = api;
        resolve(api);
      });
    });
  }
}
