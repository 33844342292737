function f() {
  return `usersnapCallback_${Math.random().toString().slice(2)}`;
}
function c(e, a, t) {
  return new Promise(n => {
    const o = f();
    if (e) {
      const d = t ? `&n=${t}` : "",
        w = `//widget.usersnap.com/${a}/load/${e}?onload=${o}${d}`;
      if (!window[o]) {
        window[o] = l => (delete window[o], n(l));
        const r = document.createElement("script");
        r.defer = !0, r.nonce = t, r.onerror = () => {
          throw new Error("Wrong api key or paused project");
        }, r.src = w, document.head.appendChild(r);
      }
    }
  });
}
const s = /* @__PURE__ */new Map(),
  i = /* @__PURE__ */new Map(),
  u = async (e, a) => {
    if (!e) throw new Error("Missing api key");
    s.has(e) || s.set(e, c(e, "global", a));
    const t = await s.get(e);
    return {
      init: async n => {
        await t.init(n);
      },
      logEvent: async n => {
        await t.logEvent(n);
      },
      show: async n => await t.show(n),
      hide: async n => {
        await t.hide(n);
      },
      destroy: async () => {
        await t.destroy();
      },
      on: (n, o) => {
        t.on(n, o);
      },
      off: (n, o) => {
        t.off(n, o);
      }
    };
  },
  h = async (e, a) => {
    if (!e) throw new Error("Missing api key");
    i.has(e) || i.set(e, c(e, "embed", a));
    const t = await i.get(e);
    return {
      init: async n => {
        await t.init(n);
      },
      on: (n, o) => {
        t.on(n, o);
      },
      off: (n, o) => {
        t.off(n, o);
      }
    };
  };
export { h as loadEmbed, u as loadSpace };