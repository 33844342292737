<footer>
  <div class="row">
    <div
      class="col-12 d-flex align-items-center justify-content-between flex-column flex-lg-row"
    >
      <div cf-main-logo routerLink="/dashboard"></div>

      <div class="my-3 my-lg-0 d-flex">
        <a target="_blank" href="https://calfrenzy.com/cookie-policy/">
          Cookie Policy
        </a>
        <a target="_blank" href="https://calfrenzy.com/terms-of-use/">
          Terms of Service
        </a>
        <a target="_blank" href="https://calfrenzy.com/privacy-policy/">
          Privacy Policy
        </a>
      </div>

      <div class="d-flex flex-wrap justify-content-center">
        <span> Copyright © {{ year }} Softlux, LLC. &nbsp;</span>
        <span> All rights reserved. </span>
      </div>
    </div>
  </div>
</footer>
