import { ELang } from './lang.enum';

export interface Locale {
  lang: string;
  abbr: ELang;
}

export const LocaleOptions: Locale[] = [
  { lang: 'English', abbr: ELang.English },
  { lang: 'Français', abbr: ELang.French },
];
