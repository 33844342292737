import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig, injectEnvironment } from '@cf/core';
import { AuthService, SessionService } from '@cf/temp/services';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

const excludes = [
  ApiConfig.tokenRefresh,
  ApiConfig.login,
  ApiConfig.changePassword,
];

const UNAUTHORIZED = 401;

// const FORBIDDEN = 403;

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  static refreshTokenInProgress = false;

  static tokenRefreshedSource = new Subject<void>();

  static tokenRefreshed$ =
    RefreshTokenInterceptor.tokenRefreshedSource.asObservable();

  private environment = injectEnvironment();

  constructor(
    private readonly authService: AuthService,
    private readonly sessionService: SessionService
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    const isAuthorized = this.authService.isAuthorized();

    if (excludes.includes(request?.url?.replace(this.environment.apiUrl, ''))) {
      return next.handle(request);
    }

    if (!isAuthorized) {
      return next.handle(request);
    }

    // eslint-disable-next-line no-param-reassign
    request = this.addAuthHeader(request);

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === UNAUTHORIZED) {
          return this.refreshToken().pipe(
            switchMap(() => {
              // eslint-disable-next-line no-param-reassign
              request = this.addAuthHeader(request);
              return next.handle(request);
            }),
            catchError((err) => {
              this.authService.killSession(true);
              return throwError(() => err);
            })
          );
          // } else if (error.status === FORBIDDEN) {
          //   this.authService.logout();
        }
        return throwError(() => error);
      })
    );
  }

  addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const access = this.sessionService.token;
    if (
      access &&
      request.url.includes('/api') &&
      !request.url.includes(ApiConfig.tokenRefresh)
    ) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${access}`,
        },
      });
    }
    return request;
  }

  refreshToken() {
    if (RefreshTokenInterceptor.refreshTokenInProgress) {
      return new Observable((observer) => {
        RefreshTokenInterceptor.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      RefreshTokenInterceptor.refreshTokenInProgress = true;
      return this.authService.refreshAccessToken().pipe(
        tap(() => {
          RefreshTokenInterceptor.refreshTokenInProgress = false;
          RefreshTokenInterceptor.tokenRefreshedSource.next();
        }),
        catchError((error: HttpErrorResponse) => {
          this.authService.killSession(true);
          return throwError(() => error);
        })
      );
    }
  }
}
